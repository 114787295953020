@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Muli:400,500,600,700,800&display=swap");
@import url("https://rsms.me/inter/inter.css");

a,
abbr,
area,
article,
aside,
audio,
b,
bdo,
blockquote,
body,
button,
canvas,
caption,
cite,
code,
col,
colgroup,
command,
datalist,
dd,
del,
details,
dialog,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
head,
header,
hgroup,
hr,
html,
i,
iframe,
img,
input,
ins,
keygen,
kbd,
label,
legend,
li,
map,
mark,
menu,
meter,
nav,
noscript,
object,
ol,
optgroup,
option,
output,
p,
param,
pre,
progress,
q,
rp,
rt,
ruby,
samp,
section,
select,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
textarea,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  background: transparent;
  border: 0;
  font-size: inherit;
  font: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  vertical-align: baseline;
  z-index: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-size: 0.625em;
}
* {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.4rem;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: currentColor;
  text-decoration: none;
}
